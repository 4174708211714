<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <location-bar
      :loading="loading"
      :with-filters="locationFilters"
      :initial-district="initialDistrict"
      @selected-district="adjustDistrict"
    />
    <div
      class="row"
      v-show="selectedDistrict && !isLoading.district"
    >
      <h6 class="flex x12">
        {{ $t('districts.plans.total_teams', { teams: teamsTotal }) }}
      </h6>
    </div>
    <hr>
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.year.value"
          type="number"
          step="1"
          :disabled="loading"
          :label="$t('districts.plans.inputs.yearInput')"
          :error="!!form.year.errors.length"
          :error-messages="translatedErrors(form.year.errors)"
          @blur="validate('year')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.manager_name.value"
          :disabled="loading"
          :label="$t('districts.inputs.managerInput')"
          :error="!!form.manager_name.errors.length"
          :error-messages="translatedErrors(form.manager_name.errors)"
          @blur="validate('manager_name')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="manager_email"
          :label="$t('districts.inputs.emailManagerInput')"
          disabled
        />
      </div>
    </div>

    <div class="row">
      <form-builder :loading="loading" :questions="questions" />
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>
<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const FormBuilder = () => import(/* webpackPrefetch: true */ '@/components/extras/FormBuilder')
const LocationBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Location')

export default {
  name: 'distric-plans-form',
  components: {
    LocationBar,
    FormBuilder,
  },
  mixins: [validatorMixin],
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    wizard: {
      type: Boolean,
      default: function () { return false },
    },
    planification: {
      type: Object,
      required: false,
      default: function () { return {} },
    },
  },
  data () {
    return {
      manager: {},
      isLoading: {
        district: false,
      },
      teamsTotal: 0,
      manager_email: '',
      locationFilters: ['regions', 'countries', 'districts'],
      initialDistrict: null,
      selectedDistrict: null,
      questions: [],
      form: {
        year: {
          value: 0,
          validate: {
            required: true,
          },
          errors: [],
        },
        manager_name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    planificationYear () {
      return this.getFormValue('year')
    },
    nextYear () {
      return Number(this.getFormValue('year')) + 1
    },
  },
  watch: {
    planification (val) {
      this.setPlanification(val)
    },
    async locale (val) {
      await this.$nextTick()
      this.validateAll()
    },
  },
  created () {
    if (this.planification) {
      this.setPlanification(this.planification)
    }

    this.getQuestions()
    if (this.planification.year) return

    const date = new Date()
    let year = date.getFullYear()
    const month = date.getMonth()
    if (month > 8) {
      year += 1
    }
    this.setFormValue('year', year)
  },
  methods: {
    async setPlanification (val) {
      this.setFormData(val)
      if (val.district) {
        this.selectedDistrict = val.district
        this.initialDistrict = this.selectedDistrict
      }
      if (Number(val.year)) {
        this.setFormValue('year', val.year)
      }
      if (val.manager_name) {
        this.setFormValue('manager_name', val.manager_name)
      }
      if (val.manager_email) {
        this.manager_email = val.manager_email
      }
      if (val.annual_answers !== '') {
        this.questions = JSON.parse(val.annual_answers)
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    fixData (data) {
      const definition = JSON.parse(data.definition)
      for (const q of definition) {
        q.value = ''
      }
      return definition
    },
    async getQuestions () {
      let response = null
      try {
        response = await this.$http.get('forms/latest/1')
      } catch (e) {
        console.log('Couldnt get dynamic form', e)
        return setTimeout(() => this.getQuestions(), 10000)
      }

      const data = this.fixData(response.data.data)
      if (this.questions.length === 0) {
        this.questions = data.slice(0)
      }
    },
    async adjustDistrict (district) {
      if (district === '') {
        this.selectedDistrict = null
        this.setFormValue('manager_name', '')
        this.manager_email = ''
        return
      }
      this.selectedDistrict = district
      if (district.manager) {
        this.setFormValue('manager_name', district.manager.name)
        this.manager_email = district.manager.email
      }
      if (this.currentUser.can('Districts', 'view')) {
        this.getTeamsTotal()
      }

      await this.$nextTick()
      this.validateAll()
    },
    async getTeamsTotal () {
      this.isLoading.district = true

      let u = false
      const districtId = this.selectedDistrict.id
      try {
        u = await this.$http.get(`districts/${districtId}`)
      } catch (err) {
        this.isLoading.district = false
        return
      }
      const teamsTotal = u.data.data.teams.length

      this.teamsTotal = teamsTotal || 0
      this.isLoading.district = false
    },
    hasErrors () {
      if (this.selectedDistrict === null) {
        this.showToast(this.$t('notifications.errors.district_needed'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return true
      }

      return false
    },
    async submit () {
      this.validateAll()
      if (!this.formReady || this.hasErrors()) return

      const planification = this.getFormData(this.planification)
      planification.district_id = this.selectedDistrict.id
      if (this.manager_email) {
        planification.manager_email = this.manager_email
      }
      const questions = []
      for (const q of this.questions) {
        const p = { ...q, title_base: this.$t(q.title) }
        questions.push(p)
      }
      planification.annual_answers = JSON.stringify(questions)

      delete planification.district
      delete planification.submitter

      this.$emit('submit', planification)
    },
  },
}
</script>
<style lang="scss" scoped>
.hidden {
  display: none;
}

.col-md-6 {
  .input-group {
    input {
      margin-top: 1em;
    }

    textarea {
      margin-top: 1.25em !important;
    }

    label {
      white-space: normal;
    }
  }
}
</style>
